
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { useI18n } from "vue-i18n";
import { NumberOrString } from "@/core/directive/type/common";
import { fulfillmentOrderOverviewData } from "@/core/directive/interface/fulfillmentOrder";
import { ApiBase, ApiFulfillmentOrders, ApiMerchant } from "@/core/api";
import { getFulfillmentSourceMap } from "@/core/directive/function/fulfillmentOrder";
import _ from "lodash";
import { formatDateTime } from "@/core/directive/function/common";

export default defineComponent({
  name: "fulfillment-order-detail",
  components: {},
  setup() {
    const { t } = useI18n();

    const route = useRoute();

    const loading = ref(true);

    const FulfillmentOrderOverviewData = ref(
      Object.assign({}, fulfillmentOrderOverviewData)
    );

    const options = ref({
      fulfillment_type: getFulfillmentSourceMap(t),
      fulfillment_order_status: new Map([]) as Map<number, string>,
      merchant: new Map([]) as Map<number, string>,
      channel: new Map([]) as Map<number, string>,
      delivery_type: new Map([]) as Map<number, string>,
    });

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["fulfillment_order_status", "fulfillment_delivery_type"],
      });
      if (data.code == 0) {
        data.data.fulfillment_order_status.items.map((item) => {
          options.value.fulfillment_order_status.set(
            Number(item.value),
            item.label
          );
        });
        data.data.fulfillment_delivery_type.items.map((item) => {
          options.value.delivery_type.set(Number(item.value), item.label);
        });
      }
    };

    const getSalesChannelsData = async (id?: NumberOrString) => {
      const { data } = await ApiMerchant.getSalesChannelsData({
        max_item: "all",
        merchant_id: id,
        status: "10",
      });
      if (data.code == 0) {
        data.data.map((item) => {
          options.value.channel.set(Number(item.value), item.label);
        });
      }
    };

    const getMerchantSourceData = async (value, id?) => {
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData(params);
      if (data.code == 0) {
        data.data.map((item) => {
          options.value.merchant.set(Number(item.value), item.label);
        });
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getFulfillmentData = async () => {
      const { data } = await ApiFulfillmentOrders.getFulfillmentOrderData({
        id: route.params.id,
      });
      if (data.code == 0) {
        FulfillmentOrderOverviewData.value = data.data;
        getMerchantSourceData("", data.data.merchant_profile.merchant);
        getSalesChannelsData(data.data.merchant_profile.merchant);
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getFulfillmentData(), getDropdownOptions()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      getFormInfo();
    });

    return {
      t,
      formatDateTime,
      loading,
      FulfillmentOrderOverviewData,
      options,
      getFormInfo,
    };
  },
});
